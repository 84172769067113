import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ModalB = ({ cancelModal }) => {
  const navigate = useNavigate();

  return (
    <div>
      <ModalWrapper>
        <section className="overlay">
          <article className="swhite">
            <AiOutlineCloseCircle
              onClick={() => {
                cancelModal(false);
              }}
              className="m-icon"
            />
            <h2>Transactions Count </h2>

            <ul className="s-list">
              <li>
                {" "}
                Transactions Today: <strong>20 </strong>
              </li>
              <li>
                Transactions this Week:<strong>220 </strong>
              </li>
              <li>
                Transactions this month: <strong>440</strong>
              </li>
            </ul>
            <button
              onClick={() => {
                navigate("/services");
              }}
            >
              View All Services
            </button>
          </article>
        </section>
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled.div`
  .overlay {
    background-color: rgba(0, 0, 0, 0.425);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .swhite {
    background-color: white;
    height: 40%;
    width: 30%;
    border-radius: 4px;
    padding: 15px;
  }
  .swhite h2 {
    text-align: center;
  }
  .m-icon {
    float: right;
    font-size: 30px;
    font-weight: 100;
    color: gray;
    cursor: pointer;
  }
  .s-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 550;
  }

  .swhite button {
    color: white;
    background-color: #28d1ff;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    outline: none;
    padding: 8px;
    margin-block: 15px;
    cursor: pointer;
  }
`;
export default ModalB;
