import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Pagination from "../../Reusable/Pagination";

const CreditTransaction = ({ getTransaction }) => {
  const [init, setInit] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [paginationLimit, setPaginationLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [endIndex, setEndIndex] = useState(currentPage * paginationLimit);
  const [startIndex, setStartIndex] = useState(endIndex - paginationLimit);
  const [startCount, setStartCount] = useState(currentPage);
  const [endCount, setEndCount] = useState(postsPerPage);

  const slicedData = getTransaction?.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <TableWrapper>
      <table className="table">
        <thead>
          <tr>
            <th style={{ display: "flex" }}>S/N</th>
            <th>TRANSACTION ID</th>
            <th>SERVICE NAME</th>
            <th>CLIENT NAME</th>
            <th>BILLER NAME</th>
            <th>NET VALUE</th>
            <th>AMOUNT</th>
            <th>COMMISSION</th>
            <th>DATE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        {getTransaction?.map((d) => {
          return (
            <tbody>
              <tr key={d?.id}>
                <td>{d.id}</td>
                <td>{d.transactionId}</td>
                <td>{d.serviceName}</td>
                <td>{d.user?.name}</td>
                <td>{d.meta?.biller}</td>
                <td>{d.netValue}</td>
                <td>{d?.amount}</td>
                <td>{d?.commission}</td>
                <td>{new Date(d?.createdAt).toLocaleString("en")}</td>
                <td
                  style={{
                    backgroundColor:
                      d.status === "SUCCESS" ? "#e1eee1" : "#fbe7e8",
                    color: d.status === "SUCCESS" ? "GREEN" : "RED",
                    display: "inline-flex",
                    borderRadius: "5px",
                    width: "60%",
                    margin: "10px",
                    padding: "7px 8px",
                    justifyContent: "center"
                  }}
                >
                  {d.status}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <Pagination
        totalData={getTransaction?.length}
        postsPerPage={postsPerPage}
        startPoint={startIndex}
        setStartPoint={setStartIndex}
        endPoint={endIndex}
        setEndPoint={setEndIndex}
        startCount={startCount}
        endCount={endCount}
        setStartCount={setStartCount}
        setEndCount={setEndCount}
        currentPage={currentPage}
        data={getTransaction}
        setCurrentPage={setCurrentPage}
        paginationLimit={paginationLimit}
        init={init}
        currentDataLength={slicedData?.length}
        setInit={setInit}
      />
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  table {
    border-collapse: collapse;
    font-size: 11.5px;
    width: 100%;
  }
  table th {
    font-weight: 500;
    text-align: left;
    font-size: 10px;
    padding: 12px;
    /* margin-block-end: 10px; */
    color: #fff;
    background-color: #28d1ff;
  }
  table tr:nth-child(odd) {
    background-color: #f6f6f6;
  }
  table td {
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
  }
  table td a {
    text-decoration: none;
  }
`;

export default CreditTransaction;
