import React, { useState } from "react";
import styled from "styled-components";
import { RxCross2 } from "react-icons/rx";
import PinInput from "react-pin-input";
import ReactCodeInput from "react-code-input";

const Password = ({ cancelModal }) => {
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [enteredPin, setEnteredPin] = useState("");
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePinChange = (pin) => {
    setEnteredPin(pin);
  };

  const handleCreatePass = () => {
    setShowCodeModal(true);
  };
  const handleReset = () => {
    cancelModal();
  };
  return (
    <>
      {!showCodeModal && (
        <PasswordWrapper>
          <div className="overlay">
            <div className="p-content">
              <RxCross2
                onClick={() => {
                  cancelModal(false);
                }}
                className="m-icon"
              />
              <p>Change Password</p>
              <article className="password-content">
                <div className="password">
                  <label>Old Password</label>
                  <input
                    type="text"
                    placeholder=" Enter old Password"
                    value={resetPassword.oldPassword}
                    onChange={(e) => {
                      setResetPassword({
                        ...resetPassword,
                        oldPassword: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="password">
                  <label>New Password</label>
                  <input
                    type="text"
                    placeholder="Enter New Password"
                    value={resetPassword.newPassword}
                    onChange={(e) =>
                      setResetPassword({
                        ...resetPassword,
                        newPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="password">
                  <label>Confirm New Password</label>
                  <input
                    type="text"
                    placeholder="Confirm Your New Password"
                    value={resetPassword.confirmPassword}
                    onChange={(e) =>
                      setResetPassword({
                        ...resetPassword,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <button className="pservice-btn" onClick={handleCreatePass}>
                  UPDATE PASSWORD
                </button>
              </article>
            </div>
          </div>
        </PasswordWrapper>
      )}
      {showCodeModal && (
        <PasswordWrapper>
          <div className="overlay">
            <div className="p-content" style={{ minHeight: "30%" }}>
              <RxCross2
                onClick={() => {
                  cancelModal(false);
                }}
                className="m-icon"
              />
              <p style={{ fontSize: "15px" }}>Enter Confirmation Code</p>
              <article className="password-content">
                <ReactCodeInput
                  type="number"
                  fields={6}
                  className="codeInput"
                  onChange={handlePinChange}
                  value={enteredPin}
                />

                <button className="pservice-btn" onClick={handleReset}>
                  Confirm Reset
                </button>
              </article>
            </div>
          </div>
        </PasswordWrapper>
      )}
    </>
  );
};

const PasswordWrapper = styled.div`
  .overlay {
    background-color: rgba(0, 0, 0, 0.425);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: linear 0.3s;
  }

  .p-content {
    background-color: white;
    min-height: 45%;
    min-width: 30%;
    border-radius: 6px;
    padding: 15px;
  }

  .m-icon {
    float: right;
    font-size: 30px;
    font-weight: 100;
    color: gray;
    /* display: flex;
    justify-content: flex-end, */
  }

  .password {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .password input {
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
  }

  .password-content {
    display: flex;
    flex-direction: column;
    /* justify-content:space-between; */
    gap: 20px;

    .codeInput {
      display: flex !important;
      justify-content: space-evenly;
      margin: 20px 0 10px;
      input {
        -moz-appearance: textfield;
        width: 30px;
        height: 30px;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
  .password input::placeholder {
    font-size: 10px;
    color: #afaeae;
  }
  .p-content p {
    text-align: center;
    font-size: 24px;
    color: #28d1ff;
    font-weight: 600;
    /* margin-block:40px; */
  }
  .password label {
    font-weight: bold;
  }

  .pservice-btn {
    align-self: center;
    background: #28d1ff;
    color: #fff;
    padding: 10px 50px;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    margin-block: 2px;
    cursor: pointer;
    /* justify-content: center; */
  }
`;

export default Password;
