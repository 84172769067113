import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../../Reusable/Pagination";
import { TailSpin } from "react-loader-spinner";

const ClientT = ({ check }) => {
  const [getClient, setGetClient] = useState([]);
  const [loading, setloading] = useState(false);

  let UseDetails = JSON.parse(localStorage.getItem("userDetails"));
  const config = {
    headers: {
      Authorization: UseDetails?.data?.data?.token
    }
  };
  const fetchClient = async () => {
    setloading(true);

    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/getClients`, config)
      .then((res) => {
        setGetClient(res.data?.data);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchClient();
  }, []);

  const [init, setInit] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [paginationLimit, setPaginationLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [endIndex, setEndIndex] = useState(currentPage * paginationLimit);
  const [startIndex, setStartIndex] = useState(endIndex - paginationLimit);
  const [startCount, setStartCount] = useState(currentPage);
  const [endCount, setEndCount] = useState(10);

  const totalData = getClient.length;

  getClient.slice();

  const slicedData = getClient?.slice(indexOfFirstPost, indexOfLastPost);
  console.log(slicedData.length);

  return (
    <>
      {loading ? (
        <TailSpin
          height="60"
          width="60"
          color="#28d1ff"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <Twrapper>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  S/N
                </th>
                <th style={{ textAlign: "center" }}>Client_ID</th>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  Date Joined
                </th>
                <th
                  style={{
                    textAlign: "center"
                  }}
                >
                  View
                </th>
              </tr>
            </thead>
            {slicedData?.map((e, idx) => {
              return (
                <>
                  <tr key={e?.userId}>
                    <td
                      style={{
                        borderRight: "1.4px solid #f5f5f5",
                        textAlign: "center"
                      }}
                    >
                      {init + idx}
                    </td>
                    <td
                      style={{
                        borderRight: "1.4px solid #f5f5f5",
                        textAlign: "center"
                      }}
                    >
                      {e?.userId}
                    </td>
                    <td
                      style={{
                        borderRight: "1.4px solid #f5f5f5",
                        textAlign: "center"
                      }}
                    >
                      {e.name}
                    </td>
                    <td
                      style={{
                        borderRight: "1.4px solid #f5f5f5",
                        textAlign: "center"
                      }}
                    >
                      {e.email}
                    </td>

                    <td>
                      {e.access === "granted" ? (
                        <div
                          style={{
                            background:
                              e.access === "granted" ? "#e1eee1" : "#fbe7e8",
                            color:
                              e.access === "granted" ? "#004d00" : "#fbe7e8",
                            display: "flex",
                            borderRadius: "5px",
                            margin: "12px",
                            padding: "4px 4px",
                            justifyContent: "center",
                            borderRight: "2px solid #f5f5f5"
                          }}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          style={{
                            background:
                              e.access === "granted" ? "#e1eee1" : "#fbe7e8",
                            color:
                              e.access === "granted" ? "#026902" : "#fbe7e8",
                            display: "flex",
                            borderRadius: "5px",
                            margin: "12px",
                            padding: "4px 4px",
                            justifyContent: "center",
                            borderRight: "2px solid #f5f5f5"
                          }}
                        >
                          In-active
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        borderLeft: "1.2px solid #f5f5f5",
                        textAlign: "center"
                      }}
                    >
                      {new Date(e?.createdAt).toLocaleString("en")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Link
                        to="/clientpage"
                        onClick={() => {
                          sessionStorage.setItem("clientID", e?.userId);
                          sessionStorage.setItem("realclientID", e?.clientId);
                          console.log(e);
                          check(false);
                        }}
                        style={{
                          background: "#28D1FF",
                          borderRadius: "4px",
                          border: "1px solid #28D1FF",
                          color: "#FFF",
                          marginTop: "16px",
                          // padding: "2px ",
                          padding: "8px 12px",
                          fontSize: "12px",
                          textDecoration: "none",
                          cursor: "pointer"
                        }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          <Pagination
            totalData={totalData}
            postsPerPage={postsPerPage}
            startPoint={startIndex}
            setStartPoint={setStartIndex}
            endPoint={endIndex}
            setEndPoint={setEndIndex}
            startCount={startCount}
            endCount={endCount}
            setStartCount={setStartCount}
            setEndCount={setEndCount}
            currentPage={currentPage}
            data={getClient}
            setCurrentPage={setCurrentPage}
            paginationLimit={paginationLimit}
            init={init}
            currentDataLength={slicedData?.length}
            setInit={setInit}
          />
        </Twrapper>
      )}
    </>
  );
};

const Twrapper = styled.div`
  table {
    border-collapse: collapse;
    font-size: 11.5px;
    width: 100%;
  }
  table th {
    font-weight: 500;
    text-align: left;
    font-size: 10px;
    padding: 12px;
    /* margin-block-end: 10px; */
    color: #fff;
    background-color: #28d1ff;
  }
  table tr:nth-child(odd) {
    background-color: #f6f6f6;
  }
  table td {
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
  }
  table td a {
    text-decoration: none;
  }
`;

export default ClientT;
