import React from "react";
import styled from "styled-components";

const Pagination = ({
  postsPerPage,
  startPoint,
  setStartPoint,
  endPoint,
  setEndPoint,
  currentPage,
  data,
  setCurrentPage,
  paginationLimit,
  init,
  setInit,
  startCount,
  endCount,
  setStartCount,
  setEndCount,
  currentDataLength,
}) => {
  const totalDataLength = data?.length;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalDataLength / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const myPageNumbers = pageNumbers.slice(startPoint, endPoint);
  const lastPage = Math.ceil(data?.length / postsPerPage);

  const nextHandler = () => {
    if (currentPage === lastPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      setInit(init + Number(postsPerPage));
      setStartCount(startCount + Number(postsPerPage));
      setEndCount(startCount + postsPerPage + postsPerPage - 1);
    }
    if (currentPage === lastPage - 1) {
      setEndCount(totalDataLength);
    }
    if (currentPage === paginationLimit) {
      setStartPoint(startPoint + 5);
      setEndPoint(endPoint + 5);
    }
  };

  const previousHandler = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      setInit(init - Number(postsPerPage));
      setStartCount(startCount - Number(postsPerPage));
      setEndCount(endCount - currentDataLength);
    }
    if (currentPage <= startPoint + 1) {
      setStartPoint(startPoint - 5);
      setEndPoint(endPoint - 5);
    }
  };

  return (
    <TableWrapper>
      <div className="left">
        <div>
          Showing {startCount} to {endCount} of {totalDataLength} entries
        </div>
      </div>
      <div className="pagination-right">
        <div className="prev" onClick={previousHandler}>
          Previous
        </div>
        <div className="numbersWrapper">
          {myPageNumbers.map((number, idx) => {
            return (
              <span
                className={currentPage === number ? "active" : ""}
                key={idx}
              >
                {number}
              </span>
            );
          })}
        </div>
        <div className="next" onClick={nextHandler}>
          Next
        </div>
      </div>
    </TableWrapper>
  );
};

export default Pagination;

const TableWrapper = styled.div`
  margin: 2rem 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  width: 100%;

  .left {
    display: flex;
    align-items: center;
    gap: 2rem;

    .limit {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      select {
        height: 30px;
        width: 50px;
        border: 1px solid #6f6f6f;
        border-radius: 2px;
        color: #6f6f6f;
      }
    }
  }

  .pagination-right {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 0.3rem;
    border: 1px solid #d7d7d780;
    justify-content: space-between;
    border-radius: 10px;

    .prev,
    .next {
      width: 80px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .prev {
      border-right: 1px solid #d7d7d780;
    }
    .next {
      border-left: 1px solid #d7d7d780;
    }
    .numbersWrapper {
      display: flex;
      align-items: center;
      height: 35px;

      span {
        border-left: 1px solid #d7d7d780;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        cursor: pointer;

        &:last-child {
          border-right: unset !important;
          /* border-right: 1px solid red; */
        }
        &.active {
          background: #28d1ff;
          color: #ffffff;
        }
      }
    }
  }
`;
