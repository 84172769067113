import React from "react";
import { Outlet, Navigate } from "react-router";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import { Toaster } from "sonner";

const SharedLayout = () => {
  let isAuthorized = false;
  const token = JSON.parse(sessionStorage.getItem("token"));

  if (token !== null) {
    isAuthorized = true;
  }
  if (!isAuthorized) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Container>
        <Sidebar />
        <div className="right">
          <Navbar />
          <Outlet />
        </div>
      </Container>
      <Toaster
        expand={false}
        visibleToasts={3}
        position="top-right"
        toastOptions={{
          style: { background: "#1a95b7", color: "#ffffff" },
        }}
      />{" "}
    </>
  );
};

export default SharedLayout;
const Container = styled.main`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .right {
    flex: 1;
    background: #f5f5f5;
    /* position: absolute; */
    /* left: 250px; */
    margin-left: 250px;
    width: calc(100vw - 260px);
    min-height: 100vh;
  }
`;
