import React, { useEffect, useState } from "react";
// import DummyData from "../component/DummyApi";
import "./Ttable.css";
import axios from "axios";
import styled from "styled-components";
import Pagination from "../../Reusable/Pagination";
import { TailSpin } from "react-loader-spinner";

const Debit = ({ debitData, loading, csvLoading }) => {
  const [init, setInit] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [paginationLimit, setPaginationLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [endIndex, setEndIndex] = useState(currentPage * paginationLimit);
  const [startIndex, setStartIndex] = useState(endIndex - paginationLimit);
  const [startCount, setStartCount] = useState(currentPage);
  const [endCount, setEndCount] = useState(10);

  const slicedData = debitData?.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <TableWrapper>
      {csvLoading || loading ? (
        <TailSpin
          height="60"
          width="60"
          color="#28d1ff"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th style={{ display: "flex" }}>ID</th>
              <th>DATE</th>
              <th>TRANSACTION ID</th>
              <th>SERVICE NAME</th>
              <th>BILLER NAME</th>
              <th>AMOUNT</th>
              <th>CLIENT NAME</th>
              <th>STATUS</th>
            </tr>
          </thead>
          {slicedData?.map((d) => {
            return (
              <>
                <tr key={d?.userId}>
                  <td>{d?.id}</td>
                  <td> {new Date(d?.createdAt).toLocaleString("en")}</td>
                  <td>{d?.transactionId}</td>
                  <td>{d?.serviceName}</td>
                  <td>{d?.billingId}</td>
                  <td>{d?.amount}</td>
                  <td>{d?.user?.name}</td>
                  <td
                    style={{
                      backgroundColor:
                        d?.status === "SUCCESS" ? "#e1eee1" : "#fbe7e8",
                      color: d?.status === "SUCCESS" ? "GREEN" : "RED",
                      display: "inline-flex",
                      borderRadius: "5px",
                      width: "60%",
                      margin: "10px",
                      padding: "7px 8px",
                      justifyContent: "center"
                    }}
                  >
                    {d?.status}
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      )}
      <Pagination
        totalData={debitData?.length}
        postsPerPage={postsPerPage}
        startPoint={startIndex}
        data={debitData}
        setStartPoint={setStartIndex}
        endPoint={endIndex}
        setEndPoint={setEndIndex}
        startCount={startCount}
        endCount={endCount}
        setStartCount={setStartCount}
        setEndCount={setEndCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginationLimit={paginationLimit}
        init={init}
        currentDataLength={slicedData?.length}
        setInit={setInit}
      />
    </TableWrapper>
  );
};

export default Debit;

const TableWrapper = styled.div`
  padding: 0 !important;
`;
