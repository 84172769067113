import React, { useEffect, useState } from "react";
import axios from "axios";
import csv from "../../assets/images/csv.png";
import "./transaction.css";
import Debit from "./Debit";
import Credit from "./Credit";
import { CgArrowsH } from "react-icons/cg";
import { toast } from "sonner";
import { saveAs } from "file-saver";
import { TailSpin } from "react-loader-spinner";

const Transaction = () => {
  const [debit, setDebit] = useState(true);
  const [credit, setCredit] = useState(false);
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const [transactions, setTransactions] = useState([]);
  const [csvTransactions, setCSVTransactions] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [limit, setlimit] = useState("50");
  const [type, setType] = useState("debit");
  let UseDetails = JSON.parse(localStorage.getItem("userDetails"));

  const config = {
    headers: {
      Authorization: UseDetails?.data?.data?.token
    }
  };

  const fetchTransactions = async () => {
    setloading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/transactions/all?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}&limit=${limit}&type=${type}`,
        config
      )
      .then((res) => {
        setTransactions(res.data?.data?.transactions?.data);
        setloading(false);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [type, limit]);

  useEffect(() => {
    // let isDateFilterAdded =
    //   dateFilter.endDate !== "" && dateFilter.startDate !== "";
    // console.log(isDateFilterAdded);
    // fetchTransactions();
    if (dateFilter.endDate !== "" && dateFilter.startDate !== "") {
      fetchTransactions();
    } else {
      return;
    }
  }, [dateFilter.endDate, dateFilter.startDate, limit, type]);
  console.log(transactions);
  let debitTransactions = transactions;
  let creditTransactions = transactions;
  console.log(creditTransactions);

  const exportCSVHandler = async () => {
    if (
      dateFilter.startDate === "" ||
      dateFilter.endDate === "" ||
      loading === true
    ) {
      toast("Date filter is required to export csv");
      return;
    } else {
      if (debit === true) {
        setCsvLoading(true);
        await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/transactions/all?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}&limit=${limit}`,
            config
          )
          .then((res) => {
            setCSVTransactions(res.data?.data?.transactions?.data);
            let header = [
              "ID",
              "Date",
              "Txn ID",
              "Service Name",
              "Biller Name",
              "Amount",
              "Client Name",
              "Status"
            ];
            let debitTransactions = res.data?.data?.transactions?.data?.filter(
              (txn) => txn.type === "debit"
            );
            console.log(debitTransactions);
            const myCsvData = JSON.parse(JSON.stringify(debitTransactions));
            let formattedData = [];

            for (let i = 0; i < myCsvData?.length; i++) {
              let info = { ...myCsvData[i] };
              let {
                id,
                createdAt,
                transactionId,
                serviceName,
                billingId,
                amount,
                status
              } = info;
              let { name } = info?.user;
              const csvData = {
                id,
                createdAt,
                transactionId,
                serviceName,
                billingId,
                amount,
                name,
                status
              };
              console.log(csvData);
              formattedData.push(csvData);
            }
            const objValues = formattedData.map((item) =>
              Object.values(item).toString()
            );
            const csv = [header, ...Object.values(objValues)].join("\n");
            console.log(csv);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "debit_transactions.csv");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (credit === true) {
        setCsvLoading(true);
        await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/transactions/all?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}&limit=100`,
            config
          )
          .then((res) => {
            setCSVTransactions(res.data?.data);
            let header = ["ID", "Date", "Amount", "Client Name", "Status"];
            let creditTransactions = res.data?.data?.transactions?.data?.filter(
              (txn) => txn.type === "credit"
            );
            const myCsvData = JSON.parse(JSON.stringify(creditTransactions));
            let formattedData = [];

            for (let i = 0; i < myCsvData?.length; i++) {
              let info = { ...myCsvData[i] };
              let { id, createdAt, amount, by, status } = info;
              const csvData = {
                id,
                createdAt,
                amount,
                by,
                status
              };
              console.log(csvData);
              formattedData.push(csvData);
            }
            const objValues = formattedData.map((item) =>
              Object.values(item).toString()
            );
            const csv = [header, ...Object.values(objValues)].join("\n");
            console.log(csv);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "credit_transactions.csv");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    }

    // // Convert data to CSV format
    // const csvContent = data.map((row) => row.join(",")).join("\n");

    // // Create a Blob object for the CSV content
    // const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // // Save the CSV file using FileSaver.js
    setCsvLoading(false);
    // saveAs(blob, "exported_data.csv");
  };

  return (
    <div className="container">
      <section className="homepage">
        <section className="C-container">
          <article className="client-head">
            <div className="transaction-header">
              <span className="spanA">Transactions</span>
              <span className="p">
                The list below consist of all the transactions on the platform
              </span>
            </div>
            <article className="T-right">
              <div className="input">
                <div className="input-group" style={{ cursor: "pointer" }}>
                  {" "}
                  <label style={{ cursor: "pointer" }}>Start-date</label>
                  <input
                    type="date"
                    placeholder="Start Date"
                    style={{ cursor: "pointer" }}
                    value={dateFilter.startDate}
                    onChange={(e) => {
                      setDateFilter((prev) => ({
                        ...prev,
                        startDate: e.target.value
                      }));
                      console.log(e);
                    }}
                  />{" "}
                </div>{" "}
                <CgArrowsH className="btn-icon" />
                <div className="input-group">
                  <label style={{ cursor: "pointer" }}>End date</label>{" "}
                  <input
                    type="date"
                    placeholder="End Date"
                    style={{ cursor: "pointer" }}
                    value={dateFilter.endDate}
                    onChange={(e) => {
                      setDateFilter((prev) => ({
                        ...prev,
                        endDate: e.target.value
                      }));
                      console.log(e);
                    }}
                  />
                </div>
              </div>

              <div className="limit">
                <p>Limit</p>
                <select
                  name="limit"
                  id="limit"
                  onChange={(e) => {
                    setlimit(e.target.value);
                  }}
                >
                  {[50, 100, 500, 1000].map((val) => {
                    return (
                      <option value={val} key={`limit${val}`} s>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                {csvLoading ? (
                  <div
                    className="csv"
                    style={{
                      padding: "5px 40px",
                      cursor: "not-allowed",
                      background: "#3d3d3d",
                      color: "#fff"
                    }}
                  >
                    <TailSpin
                      height="20"
                      width="20"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <span
                    className="csv"
                    style={
                      dateFilter.startDate === "" ||
                      dateFilter.endDate === "" ||
                      loading === true
                        ? {
                            cursor: "not-allowed",
                            background: "#3d3d3d",
                            color: "#fff"
                          }
                        : { cursor: "pointer", transition: "all 0.3s ease" }
                    }
                    onClick={exportCSVHandler}
                  >
                    <img src={csv} alt="calendar" /> <p>Export Report</p>
                  </span>
                )}
              </div>
            </article>
          </article>
          <ul className="cards">
            {/* <RouterProvider router={router}/> */}
            <p
              onClick={() => {
                setDebit(true);
                setCredit(false);
                setType("debit");
              }}
              style={{
                color: type === "debit" ? "#28D1FF" : "gray",
                borderBottom: type === "debit" ? "2px solid #28D1FF" : "none",
                cursor: "pointer"
              }}
            >
              Debits
            </p>

            <p
              onClick={() => {
                setCredit(true);
                setDebit(false);
                setType("credit");
              }}
              style={{
                color: type === "credit" ? "#28D1FF" : "gray",
                borderBottom: type === "credit" ? "2px solid #28D1FF" : "none",
                cursor: "pointer"
              }}
            >
              Credits{" "}
            </p>
          </ul>
          {credit && (
            <Credit
              creditData={creditTransactions}
              loading={loading}
              csvLoading={csvLoading}
            />
          )}
          {debit && (
            <Debit
              debitData={debitTransactions}
              loading={loading}
              csvLoading={csvLoading}
            />
          )}

          {/* Table */}
        </section>
      </section>
    </div>
  );
};

export default Transaction;
