import React, { useState, useEffect } from "react";
import axios from "axios";
// import {TiArrowUnsorted} from "react-icons/ti"
// import CreditData from '../component/TableC'
import "./Ttable.css";
import Pagination from "../../Reusable/Pagination";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";

const Credit = ({ creditData, loading, csvLoading }) => {
  //Pagination constants
  const [init, setInit] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [paginationLimit, setPaginationLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [endIndex, setEndIndex] = useState(currentPage * paginationLimit);
  const [startIndex, setStartIndex] = useState(endIndex - paginationLimit);
  const [startCount, setStartCount] = useState(currentPage);
  const [endCount, setEndCount] = useState(postsPerPage);

  const slicedData = creditData?.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <TableWrapper>
      {csvLoading || loading ? (
        <TailSpin
          height="60"
          width="60"
          color="#28d1ff"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            height: "700px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th style={{ display: "flex" }}>ID</th>
              <th>DATE</th>
              <th>AMOUNT</th>
              <th>CLIENT</th>
              <th>STATUS</th>
            </tr>
          </thead>

          {slicedData?.map((d) => {
            return (
              <>
                <tr key={d?.id}>
                  <td>{d.id}</td>
                  <td>{new Date(d?.createdAt).toLocaleString("en")}</td>
                  <td>{d.amount}</td>
                  <td>{d?.user?.name}</td>
                  <td
                    style={{
                      backgroundColor:
                        d.status === "SUCCESS" ? "#e1eee1" : "#fbe7e8",
                      color: d.status === "SUCCESS" ? "GREEN" : "RED",
                      display: "inline-flex",
                      borderRadius: "5px",
                      width: "60%",
                      margin: "10px",
                      padding: "5px 2px",
                      justifyContent: "center"
                    }}
                  >
                    {d.status}
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      )}
      <Pagination
        totalData={creditData?.length}
        postsPerPage={postsPerPage}
        startPoint={startIndex}
        data={creditData}
        setStartPoint={setStartIndex}
        endPoint={endIndex}
        setEndPoint={setEndIndex}
        startCount={startCount}
        endCount={endCount}
        setStartCount={setStartCount}
        setEndCount={setEndCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginationLimit={paginationLimit}
        init={init}
        currentDataLength={slicedData?.length}
        setInit={setInit}
      />
    </TableWrapper>
  );
};

export default Credit;

const TableWrapper = styled.div`
  padding: 0 !important;
`;
