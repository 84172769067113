import React from "react";
import notifications from "../assets/images/notifications.png";
import admin from "../assets/images/Admin.png";
import styled from "styled-components";

const Navbar = () => {
  return (
    <NavWrapper>
      <div className="img-container">
        <img src={notifications} alt="" />
      </div>
      <div className="admin">
        <img src={admin} className="img-admin" alt="" />
        <div className="adminDetails">
          <span className="span1">Administration</span>
          <span className="span2">Super Admin</span>
        </div>
      </div>
    </NavWrapper>
  );
};

export default Navbar;

const NavWrapper = styled.nav`
  display: flex;
  justify-content: flex-end;
  background: transparent;
  padding: 1rem 22px;
  gap: 2rem;
  align-items: center;
  .admin {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    .img-admin {
      width: 40px;
      height: 40px;
    }
    .adminDetails {
      display: flex;
      flex-direction: column;
    }
  }
`;
