import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import notifications from "../../assets/images/notifications.png";
import admin from "../../assets/images/Admin.png";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { CgArrowsH } from "react-icons/cg";
import csv from "../../assets/images/csv.png";
import ClientModal from "./ClientModal";
import commision from "../../assets/images/commision.png";
import value from "../../assets/images/value.png";
import DebitTransaction from "./DebitTransaction";
import CreditTransaction from "./CreditTranaction";
import { IoIosTrendingUp } from "react-icons/io";
import { BsEyeSlash } from "react-icons//bs";
import { BsEye } from "react-icons/bs";
import axios from "axios";
import Loader from "../../components/Loader";
import { toast } from "sonner";
import { saveAs } from "file-saver";
import { ReactComponent as Backbutton } from "../../assets/images/toggleOn.svg";

const ClientPage = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [icon, setIcon] = useState(true);
  const [reload, setReload] = useState(false)
  const [viewClients, setViewClients] = useState();
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const [isDebit, setIsDebit] = useState("debit");
  const [csvTransactions, setCSVTransactions] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [metrics, setMetrics] = useState();
  const [loading, setLoading] = useState(false);
  let useDetails = JSON.parse(localStorage.getItem("userDetails"));
  const token = useDetails?.data?.data?.token;
  let getID = sessionStorage.getItem("clientID");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  console.log(viewClients);

  const [count, setCount] = useState("");

  const singleClients = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/transactions/all/${getID}?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}&type=${isDebit}`,
        config
      )
      .then((res) => {
        setViewClients(res?.data.data);
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    singleClients();
    if(reload){
      singleClients();
      setReload(false)
    }
  }, [isDebit, reload]);

  useEffect(() => {
    if (dateFilter.endDate !== "" && dateFilter.startDate !== "") {
      singleClients();
    } else {
      return;
    }
  }, [dateFilter.endDate, dateFilter.startDate]);

  const debitTransactions = () => {
    return viewClients?.transactions?.data;
  };
  const creditTransactions = () => {
    return viewClients?.transactions?.data;
  };

  console.log(creditTransactions());

  const toggleIcon = () => {
    setIcon(!icon);
  };

  const exportCSVHandler = async () => {
    if (
      dateFilter.startDate === "" ||
      dateFilter.endDate === "" ||
      loading === true
    ) {
      toast("Date filter is required to export csv");
      return;
    } else {
      if (isDebit === "debit") {
        setCsvLoading(true);
        await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/transactions/all/${getID}?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`,
            config
          )
          .then((res) => {
            setCSVTransactions(res.data?.data?.transactions?.data);
            let header = [
              "ID",
              "Date",
              "Txn ID",
              "Service Name",
              "Biller Name",
              "Amount",
              "Client Name",
              "Status",
              "Net Value",
              "Commission"
            ];
            let debitTransactions = res.data?.data?.transactions?.data;
            console.log(debitTransactions);
            const myCsvData = JSON.parse(JSON.stringify(debitTransactions));
            let formattedData = [];

            for (let i = 0; i < myCsvData?.length; i++) {
              let info = { ...myCsvData[i] };
              let {
                id,
                createdAt,
                transactionId,
                serviceName,
                billingId,
                amount,
                status,
                netValue,
                commission,
                user
              } = info;
              // let { name } = user;
              let name = user?.name || '';
              const csvData = {
                id,
                createdAt,
                transactionId,
                serviceName,
                billingId,
                amount,
                name,
                status,
                netValue,
                commission
              };
              console.log(csvData);
              formattedData.push(csvData);
            }
            const objValues = formattedData.map((item) =>
              Object.values(item).toString()
            );
            const csv = [header, ...Object.values(objValues)].join("\n");
            console.log(csv);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "debit_transactions.csv");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (isDebit === "credit") {
        setCsvLoading(true);
        await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/transactions/all/${getID}?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`,
            config
          )
          .then((res) => {
            setCSVTransactions(res.data?.data);
            let header = [
              "ID",
              "Date",
              "Amount",
              "Client Name",
              "Status",
              "Net Value",
              "Commission"
            ];
            let creditTransactions = res.data?.data?.transactions?.data;
            console.log(creditTransactions);
            const myCsvData = JSON.parse(JSON.stringify(creditTransactions));
            let formattedData = [];

            for (let i = 0; i < myCsvData?.length; i++) {
              let info = { ...myCsvData[i] };
              let { id, createdAt, amount, by, status, netValue, commission } =
                info;
              const csvData = {
                id,
                createdAt,
                amount,
                by,
                status,
                netValue,
                commission
              };
              console.log(csvData);
              formattedData.push(csvData);
            }
            const objValues = formattedData.map((item) =>
              Object.values(item).toString()
            );
            const csv = [header, ...Object.values(objValues)].join("\n");
            console.log(csv);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "credit_transactions.csv");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    }

    // // Convert data to CSV format
    // const csvContent = data.map((row) => row.join(",")).join("\n");

    // // Create a Blob object for the CSV content
    // const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // // Save the CSV file using FileSaver.js
    setCsvLoading(false);
    // saveAs(blob, "exported_data.csv");
  };
  return (
    <ClientSpace>
      {loading ? (
        <Loader />
      ) : (
        <article className="main-container">
          <div className="client-space_header">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>{viewClients?.rest?.name}</h1>
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <h3>Status:</h3>
                <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                  <Backbutton />
                </div>
              </div>
            </div>
            <article className="c-right">
              <div className="input">
                <div className="input-group">
                  {" "}
                  <label>Start-date</label>
                  <input
                    type="date"
                    placeholder="Start Date"
                    style={{ cursor: "pointer" }}
                    value={dateFilter.startDate}
                    onChange={(e) => {
                      setDateFilter((prev) => ({
                        ...prev,
                        startDate: e.target.value
                      }));
                      console.log(e);
                    }}
                  />{" "}
                </div>{" "}
                <CgArrowsH className="btn-icon" />
                <div className="input-group">
                  <label>End date</label>{" "}
                  <input
                    type="date"
                    placeholder="End Date"
                    style={{ cursor: "pointer" }}
                    value={dateFilter.endDate}
                    onChange={(e) => {
                      setDateFilter((prev) => ({
                        ...prev,
                        endDate: e.target.value
                      }));
                      console.log(e);
                    }}
                  />
                </div>
                {showStatus && (
                  <ClientModal
                    clientData={viewClients}
                    cancelModal={setShowStatus}
                    setReload={setReload}
                  />
                )}
              </div>
              <span
                className="csv"
                style={
                  dateFilter.startDate === "" ||
                  dateFilter.endDate === "" ||
                  loading === true
                    ? {
                        cursor: "not-allowed",
                        background: "#3d3d3d",
                        color: "#fff"
                      }
                    : { cursor: "pointer", transition: "all 0.3s ease" }
                }
                onClick={exportCSVHandler}
              >
                <img src={csv} alt="calendar" /> <p>Download Report</p>
              </span>

              <span className="filter">
                <p> Filter By: </p>
                <select>
                  <option>Today</option>
                  <option>This week</option>
                  <option>This month</option>
                </select>
              </span>
            </article>
          </div>
          <section>
            <ul className="li-group">
              <li className="list-group">
                <div className="d-top1">
                  <span> U-balance</span>{" "}
                  <div onClick={toggleIcon}>
                    {" "}
                    {icon ? <BsEyeSlash /> : <BsEye />}
                  </div>
                </div>
                <div className="amount">
                  &#8358;
                  {icon
                    ? "**********"
                    : viewClients?.wallet?.balance
                    ? `${viewClients?.wallet?.balance
                        ?.toFixed(2)
                        .toLocaleString()}`
                    : 0}
                </div>
                <div className="action">
                  {/* <span href="/#" style={{ cursor: "pointer" }}>
                    Change Status
                  </span>{" "} */}
                  <span
                    onClick={() => {
                      setShowStatus(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Fund Wallet
                  </span>
                </div>
              </li>
              <li className="list-group">
                <div className="d-top">
                  <span> Total Transaction Count</span>
                  <img src={count} alt="" />
                </div>
                <div className="amount">
                  {viewClients?.transactions?.total
                    ? viewClients?.transactions?.total
                    : 0}
                </div>
                <div className="description">
                  {" "}
                  <span>
                    <IoIosTrendingUp
                      style={{ color: IoIosTrendingUp ? "green" : "red" }}
                    />{" "}
                  </span>{" "}
                  <span className="des">1.3 up from last week </span>
                </div>
              </li>
              <li className="list-group">
                <div className="d-top">
                  <span> Total Transaction Value</span>{" "}
                  <img src={value} alt="" />
                </div>
                <div className="amount">
                  &#8358;
                  {viewClients?.transactions?.totalValue
                    ? viewClients?.transactions?.totalValue
                        .toFixed(2)
                        .toLocaleString()
                    : 0}
                </div>
                <div className="description">
                  {" "}
                  <span>
                    <IoIosTrendingUp
                      style={{ color: IoIosTrendingUp ? "green" : "red" }}
                    />{" "}
                  </span>{" "}
                  <span className="des">1.3 up from last week </span>
                </div>
              </li>
              <li className="list-group">
                <div className="d-top">
                  <span> Total Commission</span> <img src={commision} alt="" />
                </div>
                <div className="amount">
                  &#8358;
                  {viewClients?.transactions?.totalCommission
                    ? viewClients?.transactions?.totalCommission
                        .toFixed(2)
                        .toLocaleString()
                    : 0}
                </div>
                <div className="description">
                  {" "}
                  <span>
                    <IoIosTrendingUp
                      style={{ color: IoIosTrendingUp ? "green" : "red" }}
                    />{" "}
                  </span>{" "}
                  <span className="des">1.3 up from last week </span>
                </div>
              </li>
            </ul>
          </section>
          <div>
            <h2>Recent Transaction</h2>
            <ul className="cards">
              <p
                onClick={() => {
                  setIsDebit("debit");
                }}
                style={{
                  color: isDebit === "debit" ? "#28D1FF" : "gray",
                  borderBottom:
                    isDebit === "debit" ? "2px solid #28D1FF" : "none",
                  cursor: "pointer"
                }}
              >
                Debits
              </p>

              <p
                onClick={() => {
                  setIsDebit("credit");
                }}
                style={{
                  color: isDebit === "credit" ? "#28D1FF" : "gray",
                  borderBottom:
                    isDebit === "credit" ? "2px solid #28D1FF" : "none",
                  cursor: "pointer"
                }}
              >
                Credits{" "}
              </p>
            </ul>
            {isDebit === "debit" && (
              <DebitTransaction getTransaction={debitTransactions()} />
            )}
            {isDebit === "credit" && (
              <CreditTransaction getTransaction={creditTransactions()} />
            )}
          </div>
        </article>
      )}
    </ClientSpace>
  );
};
const ClientSpace = styled.div`
  background: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  .Rnavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .right-Rnav {
    display: flex;
    align-items: center;
  }
  .Rnavbar a {
    text-decoration: none;
    color: #000000;
  }
  .lft-nav {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .back-icon {
    margin-top: 5px;
    font-size: 25px;
    font-weight: 600;
  }

  .c-right {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .input {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .input label {
    font-size: 16px;
    margin-left: 8px;
  }
  .filter {
    display: flex;
    padding-top: 12px;
    padding-inline: 15px;
    align-items: center;
    gap: 5px;
  }
  .filter p {
    font-weight: 500;
  }

  .filter select {
    height: 25px;
    /* padding:2px 6px; */
  }
  .input input {
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #d6d6d6;
  }
  .btn-icon {
    padding-top: 20px;
  }

  .csv {
    margin-block-start: 20px;
  }

  .client-space_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
  }
  .client-space_header h1 {
    font-size: 24px;
    font-weight: 700;
  }

  .list-group {
    padding: 5px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 15px;
  }
  .li-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    gap: 10px;
    margin-block-start: 20px;
    margin-block-end: 45px;
  }
  .d-top img {
    height: 40px;
  }
  .action {
    display: flex;
    /* justify-content:space-between; */
    gap: 5px;
  }
  .action span {
    text-decoration: none;
    background: #28d1ff;
    color: #fff;
    border: 1px solid #28d1ff;
    border-radius: 4px;
    padding: 9px 6px;
    font-size: 14px;
    /* padding:2px ; */
  }

  .d-top span {
    font-size: 15px;
    color: #2b2b2ba9;
  }
  .amount {
    font-weight: bold;
    font-size: 24px;
  }

  .description {
    display: flex;
    /* align-items: center; */
    gap: 5px;
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .d-top {
    display: flex;
    align-items: center;
    /* justify-content:space-between; */
    gap: 40px;
  }
  .des {
    font-size: 12px;
    font-weight: 550;
  }

  .d-top1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 40px; */
  }
`;

export default ClientPage;
