// import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Clients from "./pages/Clients/Clients";
import ClientPage from "./pages/Clients/ClientPage";
import Revenue from "./pages/RevenuePage/Revenue";
import Services from "./pages/Services/Services";
import Transaction from "./pages/Transaction/Transaction";
import Pricing from "./pages/Pricing/Pricing";
// import Playground from "./Playground";
// import Playground from "./Playground";
// import Settings from "./Settings/Settings";
// import Support from "./Support/Support";
import Login from "./pages/Login";
import { ProtectedRoute } from "./hooks";
import { createBrowserRouter } from "react-router-dom";
import SharedLayout from "./Layout/SharedLayout";
import Settings from "./pages/Settings/Settings";
import Support from "./pages/Support/Support";
import ProtectedRoutes from "./bits/ProtectedRoutes";

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: <SharedLayout />,
    children: [
      { path: "/home", element: <Home /> },
      { path: "/clients", element: <Clients /> },
      { path: "/clientpage", element: <ClientPage /> },
      { path: "/revenue", element: <Revenue /> },
      { path: "/services", element: <Services /> },
      { path: "/transactions", element: <Transaction /> },
      { path: "/pricing", element: <Pricing /> },
      { path: "/settings", element: <Settings /> },
      { path: "/support", element: <Support /> },
    ],
  },
]);

function App() {
  return (
    <div>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="clients" element={<Clients />} />
            <Route path="services" element={<Services />} />
            <Route path="transactions" element={<Transaction />} />
            <Route path="play" element={<Playground />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="revenue" element={<Revenue />} />
            <Route path="settings" element={<Settings />} />
            <Route path="support" element={<Support />} />
            <Route path="clientpage" element={<ClientPage />} />
          </Route>
        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
