import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";

const OneService = ({
  name,
  summary,
  description,
  documentationUrl,
  subService,
  serviceId,
  editService,
  setEditService,
}) => {
  const [show, setShow] = useState(false);

  return (
    <ServiceWrapper className="ser" style={{ cursor: "pointer" }}>
      <span
        className="hd"
        onClick={() => {
          return setShow(!show);
        }}
      >
        <p>{name} service</p> <AiFillCaretDown />
      </span>

      <section>
        {show && (
          <article style={{ display: "flex", flexDirection: "column" }}>
            <div className="s-dropdown">
              <div className="lft-s">
                <p className="sn">
                  <strong>Summary</strong>
                  <span>{summary}</span>
                </p>
                <p className="sn">
                  <strong>Description</strong> <span>{description}</span>
                </p>
                <p className="sn">
                  <strong>Documentation</strong>{" "}
                  <span>
                    <a href={documentationUrl}>{documentationUrl}</a>
                  </span>
                </p>
              </div>
              <div className="right-s">
                <p className="sn">
                  <strong>How To Use</strong>
                  <span>{summary}</span>
                </p>
                <p className="sn">
                  <strong>Features and Sub-services</strong>
                  <span className="F-btn">
                    {subService?.map((sub) => {
                      return <button>{sub}</button>;
                    })}
                  </span>
                </p>
                <p className="sn">
                  <strong>Pricing</strong> <span>----------</span>
                </p>
              </div>
            </div>
            <span
              className="s-btn"
              onClick={() => {
                setEditService(true);
              }}
              style={{ cursor: "pointer" }}
            >
              EDIT SERVICE
            </span>
          </article>
        )}
      </section>
    </ServiceWrapper>
  );
};

export default OneService;

const ServiceWrapper = styled.div`
  border-radius: 4px;
  .hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #28d1ff;
    font-weight: 550;
    border: 1px solid #28d1ff;
    border-radius: 4px;
    padding: 10px;
  }
  .s-dropdown {
    padding: 3px 20px;
    display: flex;
    gap: 70px;
    align-items: center;
  }
`;
