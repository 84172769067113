import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.svg";
import welcome from "../assets/images/welcome.svg";
import { TailSpin } from "react-loader-spinner";

function Login() {
  const [email_, setEmail_] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const formReady = () => {
    if (email_ && password) {
      return true;
    } else {
      return false;
    }
  };

  const handlersubmit = async (e) => {
    e.preventDefault();
    console.log("first");
    setLoading(true);
    const userDetails = await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/auths/login`,
        JSON.stringify({
          email: email_,
          password: password,
        }),
        customConfig
      )
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
    setLoading(false);
    if (userDetails?.data?.message === "Success") {
      console.log(userDetails);
      window.location.assign("/home");
    }
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    sessionStorage.setItem(
      "token",
      JSON.stringify(userDetails?.data?.data?.token)
    );
  };

  useEffect(() => {}, [formReady()]);
  return (
    <Wrapper>
      <div className="contentWrapper">
        <div className="contentLeft">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="contentRight">
          <img src={welcome} alt="welcome" className="welcomeImg" />
          <form onSubmit={handlersubmit}>
            <input
              placeholder="Email"
              type="email"
              name="email"
              onChange={(e) => {
                setEmail_(e?.target?.value);
                formReady();
                console.log(formReady());
              }}
            />

            <input
              placeholder="Password"
              type="password"
              name="password"
              onChange={(e) => {
                setpassword(e?.target?.value);
                formReady();
                console.log(formReady());
              }}
            />
            <button
              type="submit"
              className={formReady() && !loading ? "ready" : ""}
            >
              {loading ? (
                <TailSpin
                  height="35"
                  width="35"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  color="#fff"
                />
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
}

export default Login;

const Wrapper = styled.section`
  background: #fff;
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 49% #f5f7fc 49%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 49% #f5f7fc 49%
  );
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 49%, #f5f7fc 49%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f7fc",endColorstr="#ffffff",GradientType=1);
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .contentWrapper {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    width: 90%;
    height: 90%;
    border-radius: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .contentLeft {
      .logo {
        /* width: 400px; */
      }
    }
    .contentRight {
      .welcomeImg {
        width: 300px;
      }
      form {
        display: flex;
        flex-direction: column;

        input {
          border: none;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.22);
          -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.22);
          -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.22);
          margin: 0 0 1.5rem;
          height: 36px;
          border-radius: 0.2rem;
          padding: 0 1rem;
          outline: none;

          &:active,
          &:focus {
            box-shadow: 0px 0px 14px 7px rgba(40, 209, 255, 0.35);
            -webkit-box-shadow: 0px 0px 14px 7px rgba(40, 209, 255, 0.35);
            -moz-box-shadow: 0px 0px 14px 7px rgba(40, 209, 255, 0.35);
            border-radius: 0rem;
          }
        }
        button {
          border: none;
          outline: none;
          margin: 0 auto;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          /* color: #28d1ff; */
          color: #fff;
          transition: all 0.5s ease-in-out;
          cursor: not-allowed;
          font-size: 13px;
          background: #4d4b4b;

          &:disabled {
            border: none;
            outline: none;
            margin: 0 auto;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            /* color: #28d1ff; */
            color: #fff;
            transition: all 0.5s ease-in-out;
            cursor: not-allowed;
            font-size: 13px;
            background: #4d4b4b;
          }

          &.ready {
            width: 100%;
            height: 36px;
            background: #28d1ff;
            color: #ffffff;
            border-radius: 0.2rem;
            cursor: pointer;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
`;
