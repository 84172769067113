import React, { useEffect, useState } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import styled from "styled-components";
import { toast } from "sonner";
const ServiceModal = ({ cancelModal, setRefetch, refetch }) => {
  const [subService, setSubService] = useState("");
  const [summary, setSummary] = useState("");
  const [name, setName] = useState("");
  const [addedSubServices, setAddedSubServices] = useState([]);
  const [service, setService] = useState({
    name: "",
    category: "",
    documentationUrl: "",
    summary: "",
    description: "",
    howTo: "",
    subService: [],
  });

  let UseDetails = JSON.parse(localStorage.getItem("userDetails"));
  const token = UseDetails?.data?.data?.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const addServiceApi = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/services`,
        JSON.stringify(service),
        config
      )
      .then((res) => {
        if (res.status === 201) {
          cancelModal(false);
          toast.success("Service Successfully created!", {
            duration: 4000,
            position: "top-right",

            // Styling
            style: {},
            className: "",

            // Custom Icon
            icon: "👏",

            // Aria
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });

          setRefetch(!refetch);
        } else {
          cancelModal(false);
          alert(res.data?.error);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addToService = (input) => {
    setAddedSubServices((prev) => {
      return [...prev, input];
    });
  };
  const deleteItem = (index) => {
    setAddedSubServices((prev) => {
      return prev.filter((item, i) => i !== index);
    });
  };
  useEffect(() => {
    let copiedSubs = [...addedSubServices];
    setService((prev) => {
      return { ...prev, subService: copiedSubs };
    });
  }, [addedSubServices]);
  return (
    <Service>
      <section className="overlay">
        <article className="new-service">
          <div className="service-content">
            <RxCross2
              onClick={() => {
                cancelModal(false);
              }}
              className="m-icon"
            />
            <p>Create New Service</p>
            <article className="service-menu">
              <div className="service">
                <label>Name</label>
                <input
                  type="text"
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, name: e.target.value };
                    })
                  }
                  placeholder=" Service Name"
                  style={{ padding: "10px 10px 10px" }}
                  value={service.name}
                />
              </div>
              <div className="service">
                <label>Category</label>
                <input
                  type="text"
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, category: e.target.value };
                    })
                  }
                  placeholder=" Service Category"
                  style={{ padding: "10px 10px 10px" }}
                  value={service.category}
                />
              </div>
              <div className="service">
                <label>Documentation URL</label>
                <input
                  type="text"
                  placeholder=" Service Documentation Link"
                  style={{ padding: "10px 10px 10px" }}
                  value={service.documentationUrl}
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, documentationUrl: e.target.value };
                    })
                  }
                />
              </div>
              <div className="service">
                <label>Subservices (press "Enter" to add)</label>
                <div className="subServicesContainer">
                  {addedSubServices.map((addedService, idx) => {
                    return (
                      <div
                        className="addedService"
                        key={idx}
                        onClick={() => {
                          deleteItem(idx);
                        }}
                      >
                        <p className="addedS">{addedService}</p>
                        <p className="closeBtn">x</p>
                      </div>
                    );
                  })}
                  <input
                    type="text"
                    onChange={(e) => setSubService(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        addToService(e.target.value);
                        setSubService("");
                      } else {
                        return;
                      }
                    }}
                    value={subService}
                    placeholder="Subservices"
                    className="subServiceInput"
                  />
                </div>
              </div>
              <div className="service">
                <label>Summary</label>
                <input
                  type="text"
                  placeholder="Summary"
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, summary: e.target.value };
                    })
                  }
                  value={service.summary}
                />
              </div>
              <div className="service">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Service Description"
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, description: e.target.value };
                    })
                  }
                  value={service.description}
                />
              </div>
              <div className="service">
                <label>How To Use</label>
                <input
                  type="text"
                  placeholder="How to use service"
                  onChange={(e) =>
                    setService((prev) => {
                      return { ...prev, howTo: e.target.value };
                    })
                  }
                  value={service.howTo}
                />
              </div>

              <button
                className="nservice-btn"
                onClick={() => {
                  console.log(service);
                  addServiceApi();
                }}
              >
                CREATE SERVICE
              </button>
            </article>
          </div>
        </article>
      </section>
    </Service>
  );
};

const Service = styled.div`
  .overlay {
    background-color: rgba(0, 0, 0, 0.425);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .new-service {
    /* display: flex; */
    /* flex-direction: column; */
    background-color: white;
    height: 70%;
    max-height: 70%;
    overflow-y: scroll;
    width: 35%;
    border-radius: 10px;
    padding: 6px 30px;
  }

  .service {
    display: flex;
    flex-direction: column;
    gap: 2px;

    input {
      padding: 10px 10px 60px;
      border: 1px solid #d3d3d3;
      border-radius: 4px;
      /* height: 60px; */
    }
    .subServicesContainer {
      width: 100%;
      min-height: 100%;
      max-height: 150px;
      overflow-y: scroll;
      border: 1px solid #d3d3d3;
      display: flex;
      flex-wrap: wrap;
      padding: 3px;
      gap: 1rem;
      align-items: center;
      .addedService {
        padding: 10px;
        cursor: pointer;
        width: 120px;
        background: #eff8fb;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        .addedS {
          margin: 0;
          color: #222222 !important;
          font-size: 20px;
        }
        .closeBtn {
          color: #000 !important;
          font-weight: 300;
          font-size: 15px;
        }
      }
      .subServiceInput {
        border: unset;
        outline: none;
        border-radius: 4px;
        padding: 10px;
        height: 20px;
        width: 120px;
      }
    }
  }

  .service-menu {
    display: flex;
    flex-direction: column;
    /* justify-content:space-between; */
    gap: 20px;
  }
  .service input::placeholder {
    font-size: 10px;
    color: #afaeae;
  }
  .service-content p {
    text-align: center;
    font-size: 24px;
    color: #28d1ff;
    font-weight: 600;
    /* margin-block:40px; */
  }
  .service label {
    font-weight: bold;
  }

  .nservice-btn {
    align-self: center;
    background: #28d1ff;
    color: #fff;
    padding: 10px 50px;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    margin-block: 2px;
    margin-top: 30px;
    /* justify-content: center; */
  }
  .service-content .m-icon {
    float: right;
    font-size: 30px;
    font-weight: 100;
    color: gray;
    cursor: pointer;
    /* padding-block-start: 30px; */
    /* display: flex;
    justify-content: flex-end, */
  }
`;
export default ServiceModal;
