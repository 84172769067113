import React, { useState, useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import ServiceModal from "../Services/ServiceModal";
import styled from "styled-components";
import axios from "axios";
import EditService from "../Services/EditService";
import OneService from "./Service";
import { TailSpin } from "react-loader-spinner";

const Services = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [newService, setNewService] = useState(false);
  const [editService, setEditService] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setloading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  let UseDetails = JSON.parse(localStorage.getItem("userDetails"));
  const config = {
    headers: {
      Authorization: UseDetails?.data?.data?.token,
    },
  };
  const getServices = async () => {
    setloading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/services`, config)
      .then((res) => {
        setServices(res?.data?.data);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getServices();
  }, [refetch]);
  return (
    <Service>
      <div className="container">
        <section className="homepage">
          <section className="main-container">
            <article className="client-head">
              <div className="client-header">
                <span className="spanS">Services</span>
                <span className="p">
                  This is the summary of the services rendered to clients and
                  users.
                </span>
              </div>
              <article
                className="T-right"
                onClick={() => {
                  setNewService(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <span className="rgt">
                  {" "}
                  Add Service <AiOutlinePlus />
                </span>
              </article>
            </article>
            {/*  */}
            {newService && (
              <ServiceModal
                cancelModal={setNewService}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}
            {/* <ServiceModal/> */}
            {editService && (
              <EditService
                cancelModal={setEditService}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}

            {loading ? (
              <TailSpin
                height="60"
                width="60"
                color="#28d1ff"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  height: "700px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <>
                {services?.map((service) => {
                  return (
                    <OneService
                      name={service?.name}
                      summary={service?.summary}
                      description={service?.description}
                      documentationUrl={service?.documentationUrl}
                      subService={service?.subService}
                      serviceId={service?.id}
                      editService={editService}
                      setEditService={setEditService}
                    />
                  );
                })}
              </>
            )}
          </section>
        </section>
      </div>
    </Service>
  );
};

const Service = styled.div`
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  margin: 20px;
  .spanS {
    font-size: 22px;
  }
  .rgt {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #28d1ff;
    font-weight: 550;
    border: 1px solid #28d1ff;
    border-radius: 4px;
    padding: 10px;
  }
  .ser {
    /* border: 1px solid #8eeeff89; */
    border-radius: 4px;
  }
  .hd {
    display: flex;
    /* gap: 10px; */
    justify-content: space-between;
    align-items: center;
    color: #28d1ff;
    font-weight: 550;
    border: 1px solid #28d1ff;
    border-radius: 4px;
    padding: 10px;
  }
  .s-dropdown {
    padding: 3px 20px;
    display: flex;
    gap: 70px;
    align-items: center;
    /* justify-content: space-evenly; */
  }
  .sn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: left;
  }
  .sn button {
    background: #28d1ff;
    border: 1px solid #28d1ff;
    border-radius: 4px;
    padding: 6px;
    color: white;
  }
  .s-btn {
    margin: auto;
    text-decoration: none;
    background: #28d1ff;
    border: 1px solid #28d1ff;
    border-radius: 6px;
    /* display:flex; */
    width: 65%;
    text-align: center;
    padding: 10px;
    color: white;
  }
  .sn a {
    color: #28d1ff;
  }
  .F-btn {
    display: flex;
    gap: 10px;
    color: white;
  }
  .ser {
    display: flex;
    flex-direction: column;
    padding-block: 20px;
  }
`;

export default Services;
