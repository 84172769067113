import React, { useState } from "react";
import mtn from "../../../assets/images/mtn.png";
import glo from "../../../assets/images/glo.png";
import airtel from "../../../assets/images/airtel.png";
import mobile from "../../../assets/images/9mobile.png";
import adbiller from "../../../assets/images/adbiller.png";
import BillerModal from "./BillerModal";
import ServiceModal from "./ServicesModal";

const Data = () => {
  const [bmodal, setModal] = useState(false);
  const [smodal, setSmodal] = useState(false);

  return (
    <div>
      <article className="biller" style={{ cursor: "pointer" }}>
        <div onClick={() => setSmodal(true)}>
          <img className="img" src={mtn} alt="" />
        </div>
        <div onClick={() => setSmodal(true)}>
          <img className="img" src={glo} alt="" />
        </div>
        <div onClick={() => setSmodal(true)}>
          <img className="img" src={airtel} alt="" />
        </div>
        <div onClick={() => setSmodal(true)}>
          <img className="img" src={mobile} alt="" />
        </div>
        <div>
          <img
            onClick={() => {
              setModal(true);
            }}
            className="adbiller"
            src={adbiller}
            alt=""
          />
        </div>
        {smodal && <ServiceModal cancelModal={setSmodal} />}

        {bmodal && <BillerModal cancelModal={setModal} />}
      </article>
    </div>
  );
};

export default Data;
