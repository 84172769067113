import React, { useEffect } from "react";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import overview from "../../assets/images/overview.png";
import client from "../../assets/images/client.png";
import services from "../../assets/images/services.png";
import transaction from "../../assets/images/transaction.png";
import settings from "../../assets/images/settings.png";
import pricing from "../../assets/images/pricing.png";
import support from "../../assets/images/support.png";
// import logo from "../Image/logo.png"
import logout from "../../assets/images/logout.png";
import { NavLink } from "react-router-dom";
import { clearSession } from "../../bits/helpers";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const sbData = [
    {
      id: 1,
      title: "Overview",
      image: overview,
      cName: "sidelinks",
      path: "/home",
    },
    {
      id: 2,
      title: "Clients",
      image: client,
      cName: "sidelinks",
      path: "/clients",
    },
    {
      id: 3,
      title: "Services",
      image: services,
      cName: "sidelinks",
      path: "/services",
    },
    {
      id: 4,
      title: "Transactions",
      image: transaction,
      cName: "sidelinks",
      path: "/transactions",
    },
    {
      id: 5,
      title: "Pricing",
      image: pricing,
      cName: "sidelinks",
      path: "/pricing",
    },
    {
      id: 6,
      title: "Settings",
      image: settings,
      cName: "sidelinks",
      path: "/settings",
    },

    // {
    //   id: 8,
    //   title: "Logout",
    //   image: logout,
    //   cName: "sidelinks",
    //   path: "/logout",
    // },
  ];

  useEffect(() => {}, [location.pathname]);

  return (
    <sidebar className="sidebar">
      <div>
        <img className="logo" src={logo} alt="" />
      </div>
      <section className="side-links">
        {sbData.map((sbData) => {
          return (
            <NavLink to={sbData.path}>
              <li
                className={
                  location.pathname === sbData.path ? "sidelinks2" : "sidelinks"
                }
                key={sbData.id}
              >
                {
                  <img
                    src={sbData.image}
                    className={
                      location.pathname === sbData.path
                        ? "sidelinks-img"
                        : "side-links-img"
                    }
                    alt=""
                  />
                }
                {sbData.title}
              </li>
            </NavLink>
          );
        })}
        <NavLink to="/login" onClick={clearSession}>
          <li
            className={
              location.pathname === sbData.path ? "sidelinks2" : "sidelinks"
            }
          >
            <img
              src={logout}
              alt="logout"
              className={
                location.pathname === sbData.path
                  ? "sidelinks-img"
                  : "side-links-img"
              }
            />
            Logout
          </li>
        </NavLink>
      </section>
    </sidebar>
  );
};

export default Sidebar;
